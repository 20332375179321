import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import { publicRoutes, privateRoutes } from './router';
import RouteWithSubRoutes from './utils/RoutesWithSubroutes';
import AuthWrapper from './utils/authWrapper';
import { UserContext, useUser } from "./models/user";
import { ConfigProvider } from 'antd';
import Login from './pages/login/login';
import zhCN from 'antd/lib/locale/zh_CN';


function App() {

  const userStore = useUser();

  return (
    <ConfigProvider locale={zhCN}>
      <div className="App" >
        <UserContext.Provider value={userStore}>
          <Router>
            <Switch>
              {publicRoutes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
              {privateRoutes.map((route, i) => (
                <AuthWrapper key={i} {...route} RouterComponent={route.component} />
              ))}
              <Route component={Login}/>
            </Switch>
          </Router>
        </UserContext.Provider>

      </div>
    </ConfigProvider>
  );
}

export default App;
