import React,{useContext} from 'react';
import {Redirect,Route} from 'react-router-dom';
import {UserContext} from "../models/user";
import { getTokenInCookie } from '../models/utils';


function AuthWrapper(props) {
    let { RouterComponent,path,routes } = props;
    
    let { user } = useContext(UserContext);
    let token = getTokenInCookie();

    let authed = user.isAuthed ;
    // && (token&&token.length)
    
    
    return <Route 
            path={path} 
            render={props=>(authed?<RouterComponent {...props} routes={routes}/>:<Redirect to={"/"} />)}
            />;
}

export default AuthWrapper;