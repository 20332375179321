import React from 'react';
import "./style.less";

function Footer() {

  return (
      <div className="yph-footer">
          <div className="yph-footer-contact">
            {/* <div>联系客服：198 5700 5568</div> */}
            {/* <div>联系客服</div>
            <img style={{width:100,height:100}} src="https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=gQGI8TwAAAAAAAAAAS5odHRwOi8vd2VpeGluLnFxLmNvbS9xLzAybTJUdUlKMk9lY0QxMDAwMGcwN0IAAgQpiARjAwQAAAAA" alt="" /> */}
          </div>
          <div className="yph-footer-copy">Copyright © 2002-2016 WWW.QZRCW.COM All Rights Reserved 浙ICP备05002279号-1</div>
      </div>
    
  );
}

export default Footer;