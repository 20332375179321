import React, { useEffect,useContext } from 'react';
import { useLoginQr } from '../../models/loginQr';
import { UserContext } from "../../models/user";
import { Button } from 'antd';
import './style.less';


let timer: any = null;

interface loginQrType{
    gotoApp:Function,
    loginType: 1 | 2
}

function LoginQr({ gotoApp,loginType }: loginQrType) {
    let { qrCode, getQr, isTimeOut, qr_code_url_id } = useLoginQr();
    let { registerWithQrCode  } = useContext(UserContext);

    
    const startTimer = () => {
        timer = setInterval(() => {
           
            if(registerWithQrCode && (typeof registerWithQrCode === 'function')){
                registerWithQrCode(qr_code_url_id,loginType,gotoApp);
            }
          
        }, 2000);

    
    }

    const clearTimer = () => {
        clearInterval(timer);
    }



    useEffect(() => {
        getQr();
        return function clear() {
            if (timer) {
                clearInterval(timer);
            }
        }
    }, []);

    useEffect(() => {
        if (!qr_code_url_id) {
            clearTimer();
        } else {
            startTimer();
        }



    }, [qr_code_url_id]);

    return (
        <React.Fragment>
            <div className="yph-login-qr">
                <div className="yph-login-qr-timeout-msg-container">
                    <img src={qrCode} alt="login qr" />
                    {isTimeOut && <div className="yph-login-qr-timeout-mask">
                        <div className="yph-login-qr-timeout-msg">
                            等待超时,请刷新重试
                            <div style={{ marginTop: '24px' }}><Button type="primary" onClick={getQr}>刷新</Button></div>

                        </div>

                    </div>}
                </div >

            </div>

        </React.Fragment>

    );
}

export default LoginQr;