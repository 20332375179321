import asyncComponent from './utils/AsyncComponent';

const Login = asyncComponent(() => import("./pages/login/login"));

const Register = asyncComponent(() => import("./pages/login/register"));

const Company = asyncComponent(() => import("./pages/company"));

const UserCreate = asyncComponent(() => import("./pages/user/UserCeate"));

const UserHome = asyncComponent(() => import("./pages/user/UserHome"));

const CompanyCreate = asyncComponent(() => import("./pages/company/"));

const CompanyApp = asyncComponent(() => import("./pages/companyApp"));

//const HroApp =  asyncComponent(() => import("./pages/hroApp"));

//const HroCreate =  asyncComponent(() => import("./pages/hro"));

const userBind = asyncComponent(() => import("./pages/user/userBind"));

const ForgotPassword = asyncComponent(() => import("./pages/login/forgotpassword"));

//const Zfl = asyncComponent(() => import("./pages/login/fzlogin"));

const ResetPassword = asyncComponent(() => import("./pages/login/resetPassword"));

const ViewHome = asyncComponent(() => import("./pages/public/ViewHome"));


export const privateRoutes = [

    {
        path: '/c',
        component: Company,
        exact: true
    },
    {
        path: '/user',
        component: UserCreate,
        exact: true
    },
    {
        path: '/userbind',
        component: userBind,
        exact: true
    },
    {
        path: '/home',
        component: UserHome,
        exact: true
    },

    {
        path: '/company/:step',
        component: CompanyCreate,

    },

    {
        path: '/app/:company_id',
        component: CompanyApp
    },

];

export const publicRoutes = [
    {
        path: '/',
        component: Login,
        exact: true
    },
    {
        path: '/login',
        component: Login,
        exact: true
    },
    {
        path: '/register',
        component: Register,
        exact: true
    },
    {
        path: '/forgotpassword',
        component: ForgotPassword
    },
    {
        path: '/resetpassword',
        component: ResetPassword
    },
    {
        path: '/qjcy',
        component: ViewHome
    },

];



