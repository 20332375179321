import { useState } from "react";
import { getData, putDataWithToken } from "./utils";
import requestUrl from "./requests";
import { message } from "antd";


export function useLoginQr(){
    let [qrCode,setQr] = useState("");
    let [isTimeOut,setQrTimeout] = useState(false);
    let [qr_code_url_id,setQrId] = useState(0);
    let [openId,setOpenId] = useState(0);
    

    const getQr = ()=>{
        getData(requestUrl.loginQr,{source_web:3,postfix:'_qzoff'})
        .then((res)=>{
            
            setQr(res.data.official_account_login_qr_code_url);
            
            setQrId(res.data.qr_code_url_id);


            if(isTimeOut){
                setQrTimeout(false);
            }
            
            // 登录二维码一分钟过期
            setTimeout(()=>{setQrTimeout(true);setQrId(0)},60000);
        })
    }

    async function bindWechatOpenId(id:number|string,user_id:string,cb?:Function){
         
        let res = await getData(requestUrl.loginWithQr,{qr_code_url_id:id});
        
        if(res.data.official_open_id){
            const url = requestUrl.user.replace('{user_id}',user_id);
            let response = await putDataWithToken(`${url}bind_wechat_account/`,{official_open_id:res.data.official_open_id,postfix:'_qzoff'});
            if(cb){
                cb();
            }
        }
     }

    
    


    
    return { qrCode,getQr,isTimeOut,qr_code_url_id,bindWechatOpenId };

}
